.Navbar {
    height: 60px;
    position: sticky; top: 0;
    display: flex;
    
    align-items: center;
    background: rgba(0,0,0,0.9);
    z-index: 100;
  }

.Navbar.active {
    background: linear-gradient(90deg, rgb(60, 2, 190) 0%, rgb(0, 75, 190) 100%);
}
  
.Navbar > .nav-logo {
    display: flex;
    width: 175px;
    height: 25px;
    margin: 15px;
    align-items: center;
    overflow: hidden;
  }
  
.Navbar > .nav-items > a {
    color: #ffffff;
    font-size: 16px;
    text-decoration: none;
    margin: 15px;
    position: relative;
    opacity: 0.9;
    overflow: hidden;
  }
  
.Navbar > .nav-items > a:hover {
    opacity: 1;
  }

.Navbar > .nav-items > a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #ffffff;
    transition: all .45s;
  }

.Navbar > .nav-items > a:hover::before {
    width: 100%;
  }

.Navbar > .nav-toggle {
    display: none;
}

@media (max-width: 700px) {

    .Navbar {
        justify-content: space-between;
    }

    .Navbar > .nav-items{
        position: fixed;
        display: flex;
        top: 60px;
        flex-direction: column;
        background: rgba(0,0,0,0.9);
        transform: translateX(-100%);
        transition: all .45s;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .Navbar > .nav-items > a::before {
        background: transparent;
    }

    .Navbar > .nav-items.open {
        transform: translateX(0)
    }


    .Navbar > .nav-toggle {
        display: flex;
        width: 50px;
        justify-content: center;
        cursor: pointer;
        height: 50px;
        align-items: center;
    }

    .nav-toggle > .bar {
        position: relative;
        width: 32px;
        background: #ffffff;
        transition: all .45s ease-in-out;
        height: 2px;
    }

    .nav-toggle > .bar::before, .nav-toggle > .bar::after {
        content: "";
        position: absolute;
        height: 2px;
        transition: all .45s ease-in-out;
        background: #ffffff;
        border-radius: 2px;
    } 

    .nav-toggle > .bar::before {
        width: 25px;
        transform: translateY(-8px);
        right: 0;
    }

    .nav-toggle > .bar::after {
        width: 32px;
        transform: translateY(8px);       
    }

    .nav-toggle.open > .bar {
        transform: translateX(-40px);
        background: transparent;
    }

    .nav-toggle.open > .bar::before {
        width: 32px;
        transform: rotate(45deg) translate(26px, -26px);
    }

    .nav-toggle.open > .bar::after {
        width: 32px;
        transform: rotate(-45deg) translate(26px, 26px);
    }
    
    .Navbar > .nav-items > a {
        font-size: 30px;
        text-align: right;
      }
}
