@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: 'Anton', sans-serif;
}

.main {
  width: 100%;
  height: 100vh;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
