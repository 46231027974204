  video {
      object-fit: cover;
      width: 100%;
      min-height: 75em;
      max-height: 85em;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -10; 
      display: flex;
      justify-content: center;
    }

  .hero-container {
    /*background: url('../images/Esim1.png') center center/cover no-repeat;*/
    min-height: 65em;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    justify-content: center;
    margin: auto;
    overflow: hidden;

  }

  .hero-container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:rgba(0, 0, 0, 0.7);
    padding: 50px;
    padding-top: 125px;
    border-radius: 20px;
  }

  .logo{
    width: 100%;
    height: auto;
  }

  .hero-container2 > h1 {
    color: #fff;;
    font-size: 100px;
    margin-top: -100px;
    align-items: center;
    display: flex;
  }
  .white1 {
    margin-right: 15px;
  }

  .hero-container2 > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;    
  }

  .hero-btns {
    margin-top: 32px;
    left: 50;
    margin-bottom: 0;
  }

  .hero-btns .btn {
    text-decoration: none;
    margin: 10px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    font-size: 25px;
    color: #000000;
    
  }

  .hero-btns > .btn:hover {
    opacity: 1;
  }

  .hero-btns > .btn::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #000000;
    transition: all .45s;
  }

  .hero-btns > .btn:hover::before {
    width: 100%;
  }

  .blue {
    color: #648affff;
  }

@media screen and (max-width: 960px) {

      video {
      object-fit: cover;
      width: 100%;
      min-height: 75em;
      max-height: 85em;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -10; 
      display: flex;
      justify-content: center;
    }

    .hero-container2 {
      padding: 50px;
      padding-top: 170px;
    }
  
    .hero-container2 > h1 {
        font-size: 70px;
        margin-top: -150px;
        
    }
}

@media screen and (max-width: 768px) {

    .hero-container2 > h1 {
        font-size: 50px;
        margin-top: -100px;
        padding-bottom: 50px;
    }

    .hero-container2 {
      padding: 0px;
      padding-top: 170px;
      margin-bottom: 250px;
      border-radius: 0;
    }

    .hero-container2 > p {
        font-size: 30px;
        padding: 10px;
        padding-left: 40px;
    }

    .hero-btns {
      margin-bottom: 60px;
    }

    .btn {
        width: 100%;
    }
}
  
@media screen and (max-width: 330px) {

  .hero-btns .btn {
    text-decoration: none;
    margin: 10px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    font-size: 15px;
    color: #000000;
    justify-content: center;
  }
}
