.contact-container {
    background-color: rgb(101, 101, 101);
    min-height: 75vh;
    width: 100%;
    flex-direction: column;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    justify-content: center;
    margin: auto;
    overflow: hidden;
    z-index: 1;
  }
  
  .contact-container-contents{
    
    
  }

  .contact-container-contents > h1 {
    color: #fff;
    font-size: 100px;
    margin-left: 150px;
    padding-top: 160px;
    text-shadow: 0 0 3px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
  }

  .contact-container-contents > p {
    margin-top: 8px;
    font-size: 32px;
  }

  .contact-container-contents > .molemmat {
    display: flex;
    max-width: fit-content;
    margin: 20px;
    background-color: rgb(138, 138, 138);
    padding: 30px;
    margin-left: 4%;
    padding-left: 4%;
    margin-right: 0px;
    border-radius: 7px;
    padding-right: 60px;
    color: #fff;
    text-shadow: 0 0 3px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
    font-size: 35px;
    box-shadow: 0 0 10px 5px rgb(58, 58, 58);

  }

  .subheads1 {
    margin-left: 0px;
    white-space: nowrap;
    background-color: rgb(97, 97, 97);
    padding: 15px;
    border-radius: 7px;
    box-shadow: 0 0 10px 5px rgb(58, 58, 58);
    
    
  }

  .subheads2 {
    margin-left: 400px;
    white-space: nowrap;    
    background-color: rgb(97, 97, 97);
    padding: 15px;
    border-radius: 7px;
    box-shadow: 0 0 10px 5px rgb(58, 58, 58);
    
    
  }
  
  .subheads3 {
    margin-top: 50px;
    margin-left: 0px;
    white-space: nowrap;
    background-color: rgb(97, 97, 97);
    padding: 15px;
    border-radius: 7px;
    box-shadow: 0 0 10px 5px rgb(58, 58, 58);

  }

  .link1 > .facebook {
    text-decoration: none;
    font-size: 50px;
    color: #fff;
    opacity: 1;
    cursor: pointer;
    padding-right: 10px;
  }

  .link1 > .facebook:hover {
    color: black;
  }

  .link1 > .instagram {
    text-decoration: none;
    font-size: 50px;
    cursor: pointer;
    color: #fff;
    opacity: 1;
    border-radius: 20%;
    padding-left: 10px;
  }

  .link1 > .instagram:hover {
    color: black;
  }

  .subheads2 > .link1 {
    margin-top: 10px;
    margin-bottom: -5px;
    display: inline-block;
    justify-content:center;
    display: flex;
  }


  .footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgb(138, 138, 138);
    font-size: 30px;
    margin: 75px;
    padding: 15px;
    border-radius: 7px;
    color: #fff;
    text-shadow: 0 0 3px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
    box-shadow: 0 0 10px 5px rgb(58, 58, 58);
    
  }

@media screen and (max-width: 1300px) {
  .contact-container {
    flex-direction: column;
    margin: auto;
    display: flex;
    align-items: center;
  }
  .contact-container-contents {
    display: flex;
    flex-direction: column;
  }

  .contact-container-contents > .molemmat {
    display: flex;
    margin: 40px;
  }
  .subheads2 {
    margin-left: 150px;
}
}


@media screen and (max-width: 960px) {

    .contact-container {
      flex-direction: column;
      margin: auto;
      display: flex;
      align-items: center;
    }

    .contact-container-contents {
      display: flex;
      flex-direction: column;
  }
    
    .contact-container-contents > h1 {
        font-size: 75px;
        margin-top: -100px;
        block-size: fit-content;
    }

    .contact-container-contents > .molemmat {
      display: flex;
      margin: 50px;
    }

    .molemmat > .subheads2 {
      margin-left: 10px;
    }

    .footer {

    
    }
  
}

@media screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    margin: auto;
    display: flex;
    align-items: center;
    object-fit: contain;
    justify-content: center;
  }

  .contact-container-contents {
    display: flex;
    flex-direction: column;
    padding-top: -0px;
}
  
  .contact-container-contents > h1 {
      font-size: 50px;
      margin-top: -50px;
      margin-left: 125px;
  }

  .contact-container-contents > .molemmat {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    font-size: 30px;
    
    
  }

  .molemmat > .subheads2 {
    margin: 0px;
    margin-left: 0;
    margin-top: 50px;
    padding: 15px;
    padding-right: 40px;
  }

  .subheads2 > .link1 {
    margin-top: 15px;
    margin-left: 20px;
  }

  .footer {
    margin: 10px;
    padding: 15px;
    border-radius: 7px;
    font-size: 25px;
  }
  
}

@media screen and (max-width: 370px) {
  .contact-container-contents > .molemmat {
    display: flex;
    font-size: 20px;
    margin-left: 15%;
  }

  .subheads2 > .link1 {
    margin-left: 60px;
    font-size: 10px;
  }

  .contact-container-contents > h1 {
    font-size: 40px;
    margin-top: -50px;
    margin-left: 80px;
  }
  .molemmat > .subheads2 {
    padding-right: 75px;
  }

}

