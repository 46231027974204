.venekuljetukset2 {
  min-height: 50em;
  max-height: 50em;
  max-width: 100%;
  flex-direction: column;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  justify-content: center;
  overflow: hidden;
  z-index: 5;
}

.vene-container-contents > h1 {
  color: rgb(52, 52, 52);
  text-shadow: 0 0 3px rgb(255, 255, 255), 0 0 1px rgb(255, 255, 255), 0 0 1px rgb(255, 255, 255), 0 0 1px black;
  font-size: 75px;
  margin-left: 150px;
  padding-top: 50px;   
}
.vene-container-contents > .rengas-tiedot {
  color: rgb(52, 52, 52);
  text-shadow: 0 0 3px rgb(255, 255, 255), 0 0 1px rgb(255, 255, 255), 0 0 1px rgb(255, 255, 255), 0 0 1px black;
  font-size: 75px;
  margin-left: 150px;
  padding-top: 50px;   
}

.vene-container-contents > .vene-molemmat {
  display: flex;
  max-width: fit-content;
  margin: 20px;
  background-color: rgb(230, 230, 230);
  padding: 20px;
  margin-left: 4%;
  padding-left: 4%;
  margin-right: 0px;
  border-radius: 10px;
  padding-right: 60px;
  font-size: 25px;
  line-break: 5ch;
  box-shadow: 0 0 10px 5px rgb(141, 141, 141);
  
}

.vene-molemmat > .vene-subheads1 {
    margin-left: 0px;

}

.vene-molemmat > .vene-subheads2 {
  margin-left: 300px;
  padding-right: 50px;
}

.vene-paragraph {
  margin-top: 0px;
  inline-size: 500px;
  overflow-wrap: break-word;
  max-width: 40ch;
  
}

@media screen and (max-width: 1400px) {
  .vene-container-contents {
    flex-direction: column;
    margin: auto;
    display: flex;
    align-items: center;  
  }
  .vene-container-contents > .vene-molemmat {
    display: flex;
    margin-left: 0px;
    font-size: 30px;
    flex-direction: column;
    justify-content: center;
  }
  .vene-molemmat > .vene-subheads2 {
    margin: 0;
    margin-top: 50px;
  }
  .vene-container-contents > h1 {
    font-size: 75px;
    margin-left: 0px;
    padding-top: 50px;   
  }

  .vene-container-contents > .rengas-tiedot {
    font-size: 75px;
    margin-left: 0%;
    padding-top: 50px;   
  }
}


@media screen and (max-width: 960px) {
  .vene-container-contents {
    flex-direction: column;
    margin: auto;
    display: flex;
    align-items: center;  
  }

  .vene-container-contents > h1 {
    font-size: 75px;
    margin-left: 0px;
    padding-top: 50px;   
  }
  .vene-container-contents > .rengas-tiedot {
    font-size: 50px;
    margin-left: 0%;
    padding-top: 50px;   
  }

  .vene-molemmat > .vene-subheads1 {
    margin: 0;
  }
  
  .vene-molemmat > .vene-subheads2 {
    margin: 0;
    margin-top: 50px;
  }

  .vene-container-contents > .vene-molemmat {
    display: flex;
    margin-left: 25px;
    font-size: 30px;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .venekuljetukset2 {
    height: 25em;

  }

  .vene-container-contents {
    margin-left: -50px;
  }

  .vene-container-contents > h1 {
    font-size: 45px;
    margin-left: 10%;
    padding-top: 50px;
    flex-direction: column;
    display: flex;
    align-items: center;  
    justify-content: center;
  }

  .vene-container-contents > .rengas-tiedot {
    font-size: 45px;
    margin-left: 50px;
    padding-top: 50px;
  }

  .vene-container-contents > .vene-molemmat {
    font-size: 25px;
    margin-right: -5%;
    padding-right: 20px;
  }

  .vene-molemmat > .vene-subheads1 {
   
    
  }
  
  .vene-molemmat > .vene-subheads2 {
    
  }

  .vene-paragraph {
    margin-top: 0px;
    inline-size: 400px;
   
}
}

@media screen and (max-width: 550px) {
  .vene-container-contents > .rengas-tiedot {
    font-size: 30px;
    margin-left: 50px;
    padding-top: 50px;
  }
  .vene-paragraph {
    inline-size: 250px;
  }
}

@media screen and (max-width: 370px) {

  .vene-container-contents > h1 {
    font-size: 35px;
  }
  .vene-container-contents > .rengas-tiedot {
    font-size: 25px;
  }

  .vene-container-contents{
    display: flex;
    align-items: center;
  }

  .vene-container-contents > .vene-molemmat {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    font-size: 20px;
  }
  .vene-molemmat > .vene-subheads2 {
    padding-right: 0px;
  }
}

@media screen and (max-width: 300px) {

  .vene-container-contents > h1 {
    font-size: 25px;
  }

  .vene-container-contents > .rengas-tiedot {
    font-size: 20px;
  }

  .vene-container-contents > .vene-molemmat {
    font-size: 15px;
    margin-right: -5%;
    padding-right: 20px;
  }
  .vene-paragraph {
    inline-size: 200px;
  }
}