.slider {
    position: relative;
    min-height: 40em;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.veneimage {
    width: 40em;
    height: 100%;
    border-radius: 10px;
    z-index: 1;
    position: relative;
}

.background {
    min-height: 40em;
    max-width: 100%;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.veneback {
    position: absolute;
    min-width: 100%;
    min-height: 75em;
    max-height: 85em;
    z-index: -10;
    object-fit: cover;
    filter: blur(20px);
    -webkit-filter: blur(20px);
    
}

.right-arrow {
    position: absolute;
    top: 350px;
    right: 32px;
    font-size: 3rem;
    color: rgb(255, 255, 255);
    z-index: 1;
    cursor: pointer;
    user-select: none;
    opacity: 0.7;
}

.left-arrow {
    position: absolute;
    top: 350px;
    left: 32px;
    font-size: 3rem;
    color: rgb(255, 255, 255);
    z-index: 1;
    cursor: pointer;
    user-select: none;
    opacity: 0.7;
}

.slide {
    opacity: 0;
    transition-duration: 0.5s ease;
}

.slide.active {
    opacity: 1;
    transition-duration: 0.3s;
    transform: scale(1.05);
}

.backslide {
    opacity: 1;
    transition-duration: 0.5s ease;
}

.slideback.active {
    opacity: 1;
    transition-duration: 0.3s;
    transform: scale(1.05);
}


.dotstyles {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 10;
}

.dot {
  margin: 0 3px;
  cursor: pointer;
  font-size: 50px;
}
.slideactivedot {
    margin: 0 3px;
    cursor: pointer;
    font-size: 50px;
    color: rgb(173, 173, 173);
  
}

@media screen and (max-width: 960px) {
    .veneimage {
        width: 40em;
        height: 100%;
    }
    }

@media screen and (max-width: 768px) {
    .slider {
        min-height: 35em;
        max-width: 100%;
    }

    .veneimage {
        width: 100%;
        height: 100%;     
    }
    
    .right-arrow {
        right: 5px;
    }
    
    .left-arrow {
      left: 5px;
    }
}
