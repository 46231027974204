.services-container {
    min-height: 100em;
    max-width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    justify-content: center;
    overflow: hidden;
    z-index: -1;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  }
 
  .services-container > h1 {
    color: rgb(52, 52, 52);
    text-shadow: 0 0 3px rgb(255, 255, 255), 0 0 1px rgb(255, 255, 255), 0 0 1px rgb(255, 255, 255), 0 0 1px black;
    font-size: 100px;
    padding: 5%;
    
  }

  .services-palvelut {
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
  }
  
  .services-vene {
    align-items: center;
    justify-content: center;
    margin: 25px;
    margin-right: 100px;
    margin-left: 100px;
    background-color: #e6e6e6;
    padding: 50px;
    border-radius: 20px;
    box-shadow: 0 0 10px 5px rgb(141, 141, 141);
  }

  .services-vene > .subhead {
    margin-bottom: 50px;
    margin-right: 100px;
    font-size: 30px;
    

  }

  .services-vene > .paragraph {
    margin-top: 30px;
    inline-size: 300px;
    overflow-wrap: break-word;
    font-size: 25px;
  }

  .services-vene > .images {
    overflow: auto;
    border-radius: 10px;
    display: block;
    max-width:466px;
    max-height:333px;
    width: auto;
    height: auto;

  }

  .services-vene > a {
    text-decoration: none;
    color: #000000;
    display: inline-block;
    margin-top: 50px;
    
    padding: 8px 20px;
    font-size: 25px;
    position: relative;
    opacity: 0.9;
    background-color: #ffffff;
    border-radius: 7px;
    outline: none;
    border: none;
    box-shadow: 0 0 10px 5px rgb(158, 158, 158);
    
  }

  .services-vene > a:hover {
    opacity: 1;
  }

  .services-vene > a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #000000;
    transition: all .45s;
  }

  .services-vene > a:hover::before {
    width: 100%;
  }

@media screen and (max-width: 1800px) {
  .services-vene {
    margin: 0px;
    border-radius: 0px
}
}

@media screen and (max-width: 1700px) {
  .services-palvelut {
    display: flex;
    flex-direction: column;
  }
  .services-container{
    min-height: 50em;
  }

  .services-container > h1 {
    padding: 10%;
  }
  .services-vene {
    margin: 75px;
    border-radius: 7px;
  }
}

@media screen and (max-width: 960px) {
    .services-container{
      min-height: 50em;
    }
    .services-container > h1 {
        font-size: 70px;
        block-size: fit-content;
        padding: 10%;
        margin-top: 50px;
    }

    .services-palvelut {
      display: flex;
      flex-direction: column;
    } 

    .services-palvelut > .services-vene{
     margin: 75px;
    } 
}

@media screen and (max-width: 768px) {

    .services-container{
      
    }

    .services-vene > a {
      font-size: 20px;
      margin-left: 0px;
      margin-right: 237px;
      padding-left: 20px;
    }
    
    .services-vene {
      margin: -5%;
    }

    .services-container > h1 {
        font-size: 65px;
        margin-top: 50px;
        padding-top: 90px;
    }

    .services-container > p {
        font-size: 30px;
    }
    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }

    .services-palvelut {
      display: flex;
      flex-direction: column;
      
    } 

    .services-palvelut > .services-vene{
     margin: 75px;
    } 

    .services-vene > .images {
      overflow: auto;
      border-radius: 10px;
      width: 372px;
      height: 266px;
    }
  
}

@media screen and (max-width: 450px) {
  .services-palvelut > .services-vene {
    margin-left: 75px;
  }

  .services-vene > .paragraph {
    inline-size: 250px;
    font-size: 20px;
    
  }

  .services-vene > .images {
    width: 372px;
    height: 266px;
  }

  .services-vene > a {
    font-size: 20px;
    margin-left: 0px;
    margin-right: 237px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 375px) {

  .services-vene > .images {
    border-radius: 0px;
  }

  .services-palvelut > .services-vene {
    margin-left: 75px;
    justify-content: center;
    align-items: center;
  }
  .services-vene > .paragraph {
    inline-size: 250px;
    font-size: 20px;
    margin-left: 7%;
  }

  .services-vene > a {
    font-size: 15px;
    margin-left: 20px;
    margin-right: 60%;
  }

  .services-vene > .subhead {
    margin-left: 12%;
    font-size: 30px;
    
}
}

@media screen and (max-width: 325px) {
  .services-vene > .subhead {
    margin-left: 100px;
    font-size: 30px;
    }

    .services-vene > .paragraph {
      inline-size: 250px;
      font-size: 20px;
      margin-left: 75px;
    }

    .services-vene > a {
      font-size: 15px;
      margin-left: 150px;
      margin-right: 60px;
    }

}
